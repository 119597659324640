<template>
  <Transition name="fade">
    <div
      v-if="isOpen"
      ref="modal"
      role="dialog"
      class="fixed inset-0 z-50 flex flex-col bg-black/90"
    >
      <slot />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import {
  findFirstFocusableElement,
  setupTabTrapping,
  removeTabTrapping,
} from '@/utils/accessibility'

const props = defineProps<{
  isOpen: boolean
}>()
const emit = defineEmits<{
  close: [payload: Event | KeyboardEvent]
}>()
const modal = ref<HTMLElement | null>(null)

let handleTabEvent: (event: KeyboardEvent) => void

onMounted(() => {
  window.addEventListener('keyup', onKeyup)
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', onKeyup)
})

watch(
  () => props.isOpen,
  (isOpen) => {
    nextTick(() => {
      if (!modal.value) {
        return
      }

      if (isOpen) {
        const firstFocusableElement = findFirstFocusableElement(modal.value)
        firstFocusableElement?.focus()
        handleTabEvent = setupTabTrapping(modal.value)
      } else {
        removeTabTrapping(modal.value, handleTabEvent)
      }
    })
  },
)

function onKeyup(e: KeyboardEvent) {
  if (e.key === 'Escape') {
    emit('close', e as KeyboardEvent)
  }
}
</script>
